import Dexie from "dexie";
import {getSessionAppVersion, getSessionOperator} from "../../helper/session";
import moment from "moment";
import {INVOICE_TYPE, TRANSACTION_TYPE} from "../../constants";
import {get_browser, sortByDateDescending} from "../../helper/other";
import {doc, setDoc} from "firebase/firestore";
import {AUTH, DB} from "../../auth/FirebaseContext";
import {getInvoicesByUser, getInvoicesByUserForPeriod} from "../../helper/firestore";
import {deleteOnlineOrder} from "../../helper/firestore/restaurant";
import {OFFLINE_DB_NAME, offline_tables} from "../../constants/OfflineDb";

export const offlineDB = new Dexie(OFFLINE_DB_NAME);

export const createDB = () => {
    offlineDB.version(1).stores({
        all_refunded_invoices: `
        invoiceNumber,
        userLocation,
        userUid,
        buyerCostCenter,
        buyerTin,
        cashier,       
        invoiceType,
        items,
        paymentMethod,
        referentDocumentDT,
        referentDocumentNumber,
        sdcDateTime,
        taxItems,
        totalAmount,
        transactionType,
        isInsertedOffline
        `,
        all_accounting_invoices: `
        invoiceNumber,
        userLocation,
        userUid,
        buyerCostCenter,
        buyerTin,
        cashier,       
        invoiceType,
        items,
        paymentMethod,
        referentDocumentDT,
        referentDocumentNumber,
        sdcDateTime,
        taxItems,
        totalAmount,
        timestamp,
        transactionType,
        isInsertedOffline
        `,
        all_normal_invoices: `
        invoiceNumber,
        userLocation,
        userUid,
        buyerCostCenter,
        buyerTin,
        cashier,
        invoiceType,
        items,
        paymentMethod,
        referentDocumentDT,
        referentDocumentNumber,
        sdcDateTime,
        taxItems,
        timestamp,
        totalAmount,
        transactionType,
        isInsertedOffline
        `,
        all_copies_invoices: `
        invoiceNumber,
        userLocation,
        userUid,
        buyerCostCenter,
        buyerTin,
        cashier,
        invoiceType,
        items,
        paymentMethod,
        referentDocumentDT,
        referentDocumentNumber,
        sdcDateTime,
        taxItems,
        totalAmount,
        transactionType,
        isInsertedOffline
        `,
        all_training_invoices: `
        invoiceNumber,
        userLocation,
        userUid,
        buyerCostCenter,
        buyerTin,
        cashier,        
        invoiceType,
        items,
        paymentMethod,
        referentDocumentDT,
        referentDocumentNumber,
        sdcDateTime,
        taxItems,
        totalAmount,
        transactionType,
        isInsertedOffline
        `,
        all_unhandled_invoices: `
        invoiceNumber,
        userLocation,
        userUid,
        buyerCostCenter,
        buyerTin,
        cashier,       
        invoiceType,
        items,
        paymentMethod,
        referentDocumentDT,
        referentDocumentNumber,
        sdcDateTime,
        taxItems,
        totalAmount,
        transactionType,
        isInsertedOffline
        `,
        all_errors: `
        ++id,
        userUid,
        method_name,
        file_name,
        params,
        error
        `,
        checks: `
        uid,
        userUid,
        price,
        qty,
        date,
        object
        `,
        costs: `
        uid,
        name,
        group,
        price,
        quantity,
        unit,
        vat,
        isInsertedOffline
        `,
        ingredients: `
        uid,
        name,
        category,
        unit,
        quantity,
        vat,
        isInsertedOffline
        `,
        items: `
        uid,
        name,
        category,
        description,
        ean,
        hide,
        prepTime,
        restaurantGroup,
        restaurantItemsCategory,
        code,
        price,
        unit,
        incomeVat,
        vat,
        quantity,
        minimalQuantity,
        disableDiscount,
        ingredients,
        isInsertedOffline
        `,
        discounts: `
        uid,
        name,
        percentage,
        productsUids,
        isInsertedOffline
        `,
        customers: `
        uid,
        userUid,
        name,
        pib,
        mb,
        address,
        city,
        telephone,
        email,
        contactPerson,
        isInsertedOffline`,
        operators: `
        uid,
        userUid,
        group,
        secret,
        username,
        isInsertedOffline`,
        settings: `
        accounting,
        userUid,
        advance,
        articles,
        card,
        cash,
        change,
        theme,
        virman,
        isInsertedOffline`,
        factures: `
        ++id,
        userUid,
        dateOfIssue,
        items,
        jbkjs,
        number,
        paymentDate,
        supplierUid
        `,
        active_orders: `
        id,
        name,
        active,
        alert,
        finishedPreparation,
        items,
        layoutUid,
        orderType,
        submitted,
        tableUid,
        cashier,
        userUid
        `,
        layouts: `
        uid,
        operators,
        userUid,
        image,
        name,
        tables
        `
    });
    offlineDB.open();
};

// -------------- ITEMS -------------------
export async function putItemsOffline(items = []) {
    return offlineDB.open().then(async db => {
        const table = db.table(offline_tables.items);
        if (table) {
            for (const item of items) {
                table.put({
                    ...item,
                    userUid: AUTH.currentUser.uid
                });
            }
        }
    });
}

// -------------- INGREDIENTS -------------------
export async function putIngredientOffline(ingredient) {
    if (!ingredient.uid) {
        ingredient = {
            ...Object.values(ingredient)[0],
            uid: Object.keys(ingredient)[0]
        };
    }
    return offlineDB.open().then(db => {
        const table = db.table(offline_tables.ingredients);
        try {
            offlineDB.open().then(async _ => {
                if (table) {
                    let exists = await table.where("uid").equals(ingredient.uid).first();
                    if (!exists) {
                        table.put({
                            ...ingredient,
                            userUid: AUTH.currentUser.uid
                        });
                    }
                }
            });
        } catch (e) {
            console.error(e, "putIngredientOffline");
        }
    });
}

// -------------- COSTS -------------------
export async function putCostOffline(cost) {
    if (!cost.uid) {
        cost = {
            ...Object.values(cost)[0],
            uid: Object.keys(cost)[0]
        };
    }
    return offlineDB.open().then(db => {
        const table = db.table(offline_tables.costs);
        try {
            offlineDB.open().then(async _ => {
                if (table) {
                    let exists = await table.where("uid").equals(cost.uid).first();
                    if (!exists) {
                        table.put({
                            ...cost,
                            userUid: AUTH.currentUser.uid
                        });
                    }
                }
            });
        } catch (e) {
            console.error(e, "putCostOffline");
        }
    });
}

// -------------- DISCOUNTS -------------------
export async function putDiscountOffline(discount) {
    if (!discount.uid) {
        discount = {
            ...discount,
            uid: Object.keys(discount)[0]
        };
    }
    return offlineDB.open().then(db => {
        const table = db.table(offline_tables.discounts);
        try {
            offlineDB.open().then(async _ => {
                if (table) {
                    let exists = await table.where("uid").equals(discount.uid).first();
                    if (!exists) {
                        table.put({
                            ...discount,
                            userUid: AUTH.currentUser.uid
                        });
                    }
                }
            });
        } catch (e) {
            console.error(e, "putDiscountOffline");
        }
    });
}

export async function getAllOfflineDiscounts() {
    return offlineDB.open().then(db => {
        const table = db.table("discounts");
        if (table) {
            return table.toArray();
        }
    });
}

export async function updateItemOffline(item) {
    return offlineDB.open().then(db => {
        const table = db.table(offline_tables.items);
        if (table) {
            return table.update(item.uid, item);
        }
    });
}

export async function getLastInsertedOfflineItem() {
    return offlineDB.open().then(async db => {
        const table = db.table(offline_tables.items);
        if (table) {
            let userUid = AUTH.currentUser.uid;
            try {
                let existUser = await table.where("userUid").equals(userUid).reverse().first();
                if (existUser) {
                    return existUser;
                } else {
                    return undefined;
                }
            } catch (e) {
                return undefined;
            }
        }
    });
}

export async function putItemOffline(item) {
    if (!item.uid) {
        item = {
            ...Object.values(item)[0],
            uid: Object.keys(item)[0]
        };
    }
    return offlineDB.open().then(db => {
        const table = db.table(offline_tables.items);
        try {
            offlineDB.open().then(async _ => {
                if (table) {
                    let exists = await table.where("uid").equals(item.uid).first();
                    if (!exists) {
                        table.put({
                            ...item,
                            userUid: AUTH.currentUser.uid
                        });
                    }
                }
            });
        } catch (e) {
            console.error(e, "putItemOffline");
        }
    });
}

export async function getItemImageOffline(uid) {
    return offlineDB.open().then(db => {
        const table = db.table(offline_tables.items);
        if (table) {
            return table.where("uid").equals(uid).first();
        }
        return [];
    });
}

export async function getAllOfflineInvoicesFromTable(tableName, location, currentPage, pageSize) {
    const filterByUserUid = item => item.userUid === AUTH.currentUser.uid;
    return offlineDB.open().then(async db => {
        let table = db.table(tableName);
        let obj = {
            count: 0,
            result: []
        };
        if (location) {
            obj.count = await table.where("userLocation").equals(location + AUTH.currentUser.uid).count();
            obj.result = await table.orderBy("sdcDateTime").filter((item) => item.userLocation === location + AUTH.currentUser.uid).reverse()
                .offset(currentPage * pageSize).limit(pageSize).toArray();
            return obj;
        }
        obj.count = await table.where("userUid").equals(AUTH.currentUser.uid).count();
        obj.result = await table.orderBy("sdcDateTime").filter(filterByUserUid).reverse().offset(currentPage * pageSize).limit(pageSize).toArray();
        return obj;
    });
}

// export async function getAllOfflineOrders() {
//     return offlineDB.open().then(async db => {
//         const table = db.table(offline_tables.active_orders);
//         if (table) {
//             return table.filter(obj =>
//                 obj.userUid === AUTH.currentUser.uid &&
//                 obj.active === true
//             ).toArray();
//         }
//         return [];
//     });
// }

// export async function transferOrderToAnotherTableOffline(order, tableUid) {
//     return offlineDB.open().then(db => {
//         const table = db.table(offline_tables.active_orders);
//         if (table) {
//             return table.update(order.id, {
//                 ...order,
//                 tableUid: tableUid
//             });
//         }
//     });
// }

// export async function getAllActiveOrdersByTableIdOffline(tableUid) {
//     return offlineDB.open().then(db => {
//         const table = db.table(offline_tables.active_orders);
//         if (table) {
//             return table.filter(obj => obj.userUid === AUTH.currentUser.uid &&
//                 (obj.active === true || obj.alert === true) &&
//                 obj.tableUid === tableUid).reverse().toArray();
//         }
//         return [];
//     });
// }

// export async function getNotSubmittedOrderByTableIdOffline(tableUid) {
//     return offlineDB.open().then(async db => {
//         const table = db.table(offline_tables.active_orders);
//         if (table) {
//             return table.filter(obj => obj.userUid === AUTH.currentUser.uid &&
//                 obj.submitted === false &&
//                 obj.tableUid === tableUid).first();
//         }
//         return undefined;
//     });
// }

// BROJI KOLIKO ODREDJENOG ITEMA IMA NA SVIM STOLOVIMA ZBOG ISITEMQUANTITYREQUIRED
// export async function countItemsOnTables(itemUid) {
//     try {
//         const db = await offlineDB.open();
//         const table = db.table(offline_tables.active_orders);
//
//         let count = 0;
//
//         if (table) {
//             const orders = await table.filter(o => o.userUid === AUTH.currentUser.uid).toArray();
//             orders.forEach(order => {
//                 order.items.forEach(item => {
//                     if (item.uid === itemUid && !isNaN(item?.quantity)) {
//                         count = count + item.quantity;
//                     }
//                 })
//             })
//         }
//         return count;
//     } catch (error) {
//         // putLogToFirebase(countItemsOnTables, "offlineDB", itemUid, error);
//         throw error;
//     }
// }

export function checkRestaurantItemsCategory(data) {
    const hasBarCategory = data.some(item => item.restaurantGroup === "Šank");
    const hasKitchenCategory = data.some(item => item.restaurantGroup === "Kuhinja");

    if (hasBarCategory && hasKitchenCategory) {
        return 0;
    } else if (hasBarCategory) {
        return 2;
    } else if (hasKitchenCategory) {
        return 1;
    }
}

export async function submitOrderOffline(orderId, orderType) {
    return offlineDB.open().then(db => {
        const table = db.table(offline_tables.active_orders);
        if (table) {
            return table.update(orderId, {
                submitted: true,
                finishedPreparation: false
            });
        }
    });
}

// export const getOrderTypeFromItem = (item) => {
//     return item?.restaurantGroup === RESTAURANT_GROUPS_OBJECT.kitchen ?
//         ORDER_TYPE.kitchen : (item?.restaurantGroup === RESTAURANT_GROUPS_OBJECT.bar ?
//             ORDER_TYPE.bar : ORDER_TYPE.all);
// };
//
// export const concatOrderType = (order, newItem) => {
//     let group = getOrderTypeFromItem(newItem);
//     if (!order.orderType) {
//         if (!newItem.restaurantGroup) {
//             return ORDER_TYPE.all;
//         } else {
//             return group;
//         }
//     }
//     switch (order.orderType) {
//         case ORDER_TYPE.bar:
//             if (group === ORDER_TYPE.bar)
//                 return ORDER_TYPE.bar;
//             return ORDER_TYPE.all;
//         case ORDER_TYPE.kitchen:
//             if (group === ORDER_TYPE.kitchen)
//                 return ORDER_TYPE.kitchen;
//             return ORDER_TYPE.all;
//         default:
//             return ORDER_TYPE.all;
//     }
// };

// export async function removeLocalOrderFromTableOffline(tableUid) {
//     return offlineDB.open().then(async db => {
//         const table = db.table(offline_tables.active_orders);
//         if (table) {
//             table.delete(tableUid);
//         }
//     })
// }

// export async function updateLocalOrderFromTableOffline(tableUid, data) {
//     return offlineDB.open().then(async db => {
//         const table = db.table(offline_tables.active_orders);
//         if (table) {
//             table.update(tableUid, data);
//         }
//     })
// }
//
// export async function removeAllProductsOnTableOffline(tableUid) {
//     return offlineDB.open().then(async db => {
//         const table = db.table(offline_tables.active_orders);
//         if (table) {
//             let order = await table.filter(tableItem =>
//                 tableItem.userUid === AUTH.currentUser.uid &&
//                 tableItem.tableUid === tableUid &&
//                 tableItem.submitted === false).first();
//             table.delete(order.id);
//         }
//     })
// }

// export async function addProductOnTableOffline(order) {
//     // const itemGroup = getOrderTypeFromItem(order);
//     try {
//         const db = await offlineDB.open();
//         const table = db.table(offline_tables.active_orders);
//
//         if (!table) {
//             return null;
//         }
//         const orderType = checkRestaurantGroups(order)
//         await table.put({...order, submitted: true, seen: false, orderType: orderType});
//         return order;
//         //return await getNotSubmittedOrderByTableIdOffline(tableData.uid);
//     } catch (error) {
//         console.error('An error occurred:', error);
//         return null;
//     }
// }


// -------------- LAYOUTS -------------------
export async function getAllLayoutsOffline() {
    return offlineDB.open().then(db => {
        const table = db.table(offline_tables.layouts);
        if (table) {
            return table.filter(item => item.userUid === AUTH.currentUser.uid).toArray();
        }
        return [];
    });
}

export async function addLayoutOffline(layout) {
    return offlineDB.open().then(db => {
        const table = db.table(offline_tables.layouts);
        if (table) {
            return table.put(layout);
        }
    });
}

// -------------- INVOICES -------------------
const handleInvoiceType = (invoice) => {
    if (invoice.transactionType === TRANSACTION_TYPE.refund) {
        if (invoice.invoiceType !== INVOICE_TYPE.copy && invoice.invoiceType !== INVOICE_TYPE.proforma && invoice.invoiceType !==
            INVOICE_TYPE.training) {
            return offline_tables.all_refunded_invoices;
        }
    }
    switch (invoice.invoiceType) {
        case INVOICE_TYPE.normal:
            return offline_tables.all_normal_invoices;
        case INVOICE_TYPE.proforma:
            return offline_tables.all_accounting_invoices;
        case INVOICE_TYPE.copy:
            return offline_tables.all_copies_invoices;
        case INVOICE_TYPE.training:
            return offline_tables.all_training_invoices;
        default:
            return offline_tables.all_unhandled_invoices;
    }
};

export async function insertAllUserInvoicesOfflineForPeriod(period) {
    return new Promise(async (resolve, reject) => {
        await getInvoicesByUserForPeriod([], resolve, reject, period);
    });
}

export async function insertAllUserInvoicesOffline() {
    return new Promise(async (resolve, reject) => {
        await getInvoicesByUser(null, [], resolve, reject);
    });
}

export async function getRefundedOfflineInvoiceByReferentDocumentNumber(referentDocumentNumber) {
    return offlineDB.open().then(async db => {
        let table = db.table(offline_tables.all_refunded_invoices);
        return table.where("referentDocumentNumber").equalsIgnoreCase(referentDocumentNumber).first();
    });
}

export async function getLastInsertedOfflineInvoice() {
    try {
        return offlineDB.open().then(async db => {
            let tables = [offline_tables.all_accounting_invoices, offline_tables.all_refunded_invoices,
                offline_tables.all_normal_invoices, offline_tables.all_training_invoices,
                offline_tables.all_copies_invoices];
            let lastInvoice;
            for (const table1 of db.tables) {
                if (tables.includes(table1.name)) {
                    let invoice = await table1.orderBy("sdcDateTime")
                        .filter((item) => item.userUid === AUTH.currentUser.uid && item.isInsertedOffline === false)
                        .reverse().first();
                    if (!lastInvoice) {
                        lastInvoice = invoice;
                    } else {
                        if (invoice) {
                            if (moment(invoice.sdcDateTime).isAfter(moment(lastInvoice.sdcDateTime))) {
                                lastInvoice = invoice;
                            }
                        }
                    }
                }
            }
            return lastInvoice;
        });
    } catch (e) {
        console.error("getLastInsertedOfflineInvoice", e);
        return undefined;
    }
}

export async function putInvoiceOffline(invoice) {
    try {
        let tableName = handleInvoiceType(invoice);
        offlineDB.open().then(async db => {
            const table = db.table(tableName);
            if (table) {
                let splitInvoiceNumber = invoice.invoiceNumber.split("-");
                let locationUserUid = splitInvoiceNumber[0] + AUTH.currentUser.uid;
                let exist = await table.where("invoiceNumber").equals(invoice.invoiceNumber).first();
                if (!exist) {
                    table.put({
                        ...invoice,
                        userUid: AUTH.currentUser.uid,
                        userLocation: locationUserUid
                    });
                }
            }
        });
    } catch (e) {
        console.error("putInvoiceOffline", e);
    }
}

export async function getAllOfflineInvoiceForReportBySdcDateTime(dateFrom, dateTo, location, operator) {
    return offlineDB.open().then(async db => {
        let tables = [offline_tables.all_refunded_invoices, offline_tables.all_normal_invoices];
        let all = [];
        for (const table1 of db.tables) {
            if (tables.includes(table1.name)) {
                let invoices;
                if (location && operator) {
                    invoices = await table1.where("sdcDateTime").between(dateFrom, dateTo)
                        .filter(item => {
                            if (location === "SVE") {
                                return item.userUid === AUTH.currentUser.uid &&
                                    item.cashier === operator?.username;
                            } else {
                                return item.userUid === AUTH.currentUser.uid &&
                                    (item.invoiceNumber.startsWith(location?.JID) || item.invoiceNumber.startsWith(location))
                                    &&
                                    item.cashier === operator?.username;
                            }

                        }).toArray();
                } else if (location && location !== "SVE") {
                    invoices = await table1.where("sdcDateTime").between(dateFrom, dateTo)
                        .filter(item => {
                            return item.userUid === AUTH.currentUser.uid &&
                                (item.invoiceNumber.startsWith(location?.JID) || item.invoiceNumber.startsWith(location))
                        }).toArray();
                } else if (operator) {
                    invoices = await table1.where("sdcDateTime").between(dateFrom, dateTo)
                        .filter(item => {
                            return item.userUid === AUTH.currentUser.uid &&
                                item.cashier === operator?.username;
                        }).toArray();
                } else {
                    invoices = await table1.where("sdcDateTime").between(dateFrom, dateTo)
                        .filter(item => {
                            return item.userUid === AUTH.currentUser.uid;
                        }).toArray();
                }
                all = [...all, ...invoices];
            }
        }
        return all;
    });
}

export async function getFirstInsertedOfflineInvoice() {
    try {
        return offlineDB.open().then(async db => {
            let tables = [
                offline_tables.all_accounting_invoices,
                offline_tables.all_refunded_invoices,
                offline_tables.all_normal_invoices,
                offline_tables.all_training_invoices,
                offline_tables.all_copies_invoices];
            let firstInvoice;
            for (const table1 of db.tables) {
                if (tables.includes(table1.name)) {
                    let invoice = await table1.orderBy("sdcDateTime")
                        .filter((item) => item.userUid === AUTH.currentUser.uid && item.isInsertedOffline === false)
                        .first();
                    if (!firstInvoice) {
                        firstInvoice = invoice;
                    } else if (invoice) {
                        if (moment(invoice.sdcDateTime).isBefore(moment(firstInvoice.sdcDateTime))) {
                            firstInvoice = invoice;
                        }
                    }
                }
            }
            return firstInvoice;
        });
    } catch (e) {
        console.error("getFirstInsertedOfflineInvoice", e);
        return undefined;
    }
}


export async function getAllOfflineInvoiceByBuyerTin(buyerTin) {
    return offlineDB.open().then(async db => {
        let invoice = await db.table(offline_tables.all_normal_invoices)
            .filter(item => item.userUid === AUTH.currentUser.uid && item.buyerTin?.includes(buyerTin)).toArray();
        if (invoice) {
            return invoice;
        }
        return [];
    });
}

export async function getAllOfflineInvoicesFromTables() {
    try {
        return offlineDB.open().then(async db => {
            let tables = [offline_tables.all_accounting_invoices,
                offline_tables.all_normal_invoices,
                offline_tables.all_training_invoices,
                offline_tables.all_copies_invoices,
                offline_tables.all_refunded_invoices];
            const arr = [];
            for (const table1 of db.tables) {
                if (tables.includes(table1.name)) {
                    let invoice = await table1.toArray();
                    arr.push(invoice);
                }
            }
            const hash = {};
            const merged = [];

            arr.forEach((arr) => {
                arr.forEach((data) => {
                    if (!hash[data.invoiceNumber]) {
                        hash[data.invoiceNumber] = true;
                        merged.push(data);
                    }
                });
            });
            return sortByDateDescending(merged);
        });
    } catch (e) {
        console.error("getLastInsertedOfflineInvoice", e);
        return undefined;
    }
}

export async function getOfflineInvoiceByOrderID(orderID){
    return offlineDB.open().then(async db => {
        let tables = [offline_tables.all_accounting_invoices, offline_tables.all_refunded_invoices,
            offline_tables.all_normal_invoices, offline_tables.all_training_invoices,
            offline_tables.all_copies_invoices];
        for (const table1 of db.tables) {
            if (tables.includes(table1.name)) {
                let invoice = await table1.where("timestamp").equals(orderID).first();
                if (invoice) {
                    return invoice;
                }
            }
        }
        return undefined;
    });
}

export async function getOfflineInvoiceByInvoiceNumber(invoiceNumber) {
    return offlineDB.open().then(async db => {
        let tables = [offline_tables.all_accounting_invoices, offline_tables.all_refunded_invoices,
            offline_tables.all_normal_invoices, offline_tables.all_training_invoices,
            offline_tables.all_copies_invoices];
        for (const table1 of db.tables) {
            if (tables.includes(table1.name)) {
                let invoice = await table1.where("invoiceNumber").equals(invoiceNumber).first();
                if (invoice) {
                    return invoice;
                }
            }
        }
        return undefined;
    });
}

export async function getCompensationInvoiceByInvoiceNumberOrBuyerId(searchParam, searchBuyer) {
    return offlineDB.open().then(async db => {
        let tables = [offline_tables.all_normal_invoices, offline_tables.all_accounting_invoices];
        let all = []
        for (const table1 of db.tables) {
            if (tables.includes(table1.name)) {
                let invoices;
                invoices = await table1.filter(invoice => {
                    return invoice.userUid === AUTH.currentUser.uid && invoice.buyerTin &&
                        (invoice.invoiceNumber.includes(searchParam) && invoice.buyerTin.includes(searchBuyer));
                }).toArray();
                all = [...all, ...invoices];
            }
        }

        return all;
    });
}

export async function getInvoiceForCompensation(invoiceNumber) {

    return offlineDB.open().then(async db => {
        let tables = [offline_tables.all_accounting_invoices, offline_tables.all_normal_invoices];
        for (const table1 of db.tables) {
            if (tables.includes(table1.name)) {
                let invoice = await table1.where("invoiceNumber").equals(`${invoiceNumber}`).first();
                if (invoice) {
                    return invoice;
                }
            }
        }
        return undefined;
    });
}

export async function getAllOfflineInvoiceForReportByItemNameOrGroupName(location, itemName, groupName) {
    return offlineDB.open().then(async db => {
        let tables = [offline_tables.all_refunded_invoices, offline_tables.all_normal_invoices];
        let all = [];
        for (const table1 of db.tables) {
            if (tables.includes(table1.name)) {
                let invoices;
                if (location) {
                    invoices = await table1.filter(invoice => {
                        return invoice.userUid === AUTH.currentUser.uid &&
                            invoice.invoiceNumber.startsWith(location) &&
                            invoice.items.some(item => itemName ? item.name.startsWith(itemName) :
                                (item.restaurantGroup && item.restaurantGroup.startsWith(groupName)));
                    }).toArray();
                } else {
                    invoices = await table1.filter(invoice => {
                        return invoice.userUid === AUTH.currentUser.uid &&
                            invoice.items.some(item => item.name.startsWith(itemName));
                    }).toArray();
                }
                all = [...all, ...invoices];
            }
        }
        return all;
    });
}

export async function getAllOfflineInvoiceByTableUid(location, tableUid) {
    return offlineDB.open().then(async db => {
        let tables = [offline_tables.all_refunded_invoices, offline_tables.all_normal_invoices];
        let all = [];
        for (const table1 of db.tables) {
            if (tables.includes(table1.name)) {
                let invoices;
                if (location) {
                    invoices = await table1.filter(invoice => {
                        return invoice.userUid === AUTH.currentUser.uid &&
                            invoice.invoiceNumber.startsWith(location) &&
                            invoice.internalData && invoice.internalData.tableUid === tableUid;
                    }).toArray();
                } else {
                    invoices = await table1.filter(invoice => {
                        return invoice.userUid === AUTH.currentUser.uid &&
                            invoice.internalData && invoice.internalData.tableUid === tableUid;
                    }).toArray();
                }
                all = [...all, ...invoices];
            }
        }
        return all;
    });
}

export async function getChargeFreeInvoiceFromOfflineDB(dateFrom, dateTo, location) {
    return offlineDB.open().then(async db => {
        let tables = [offline_tables.all_refunded_invoices, offline_tables.all_normal_invoices];
        let all = [];
        for (const table1 of db.tables) {
            if (tables.includes(table1.name)) {
                let invoices;
                if (location) {
                    invoices = await table1.where("sdcDateTime")
                        .between(moment(dateFrom).format('YYYY-MM-DDTHH:mm:ss'),
                            moment(dateTo).format('YYYY-MM-DDTHH:mm:ss'))
                        .filter(item => {
                            return item.userUid === AUTH.currentUser.uid &&
                                (item.invoiceNumber.startsWith(location?.JID) || item.invoiceNumber.startsWith(location)) &&
                                item?.internalData?.chargeFree;
                        }).toArray();
                } else {
                    invoices = await table1.where("sdcDateTime")
                        .between(moment(dateFrom).format('YYYY-MM-DDTHH:mm:ss'),
                            moment(dateTo).format('YYYY-MM-DDTHH:mm:ss'))
                        .filter(item => {
                            return item.userUid === AUTH.currentUser.uid &&
                                item?.internalData?.chargeFree;
                        }).toArray();

                }
                all = [...all, ...invoices];
            }
        }
        return all;
    });
}

export async function updateQuantityOffline(uid, quantity, type) {
    try {
        return offlineDB.open().then(async db => {
            let table = db.table(offline_tables.items);
            if (type === "ingrediants") {
                table = db.table(offline_tables.ingredients);
            } else if (type === "costs") {
                table = db.table(offline_tables.costs);
            }
            if (table) {
                let exists = await table.where("uid").equals(uid).first();
                if (exists) {
                    return table.update(uid, {quantity: (exists.quantity || 0) + quantity});
                }
            }
        });

    } catch (e) {
        console.error(e, "putDiscountOffline");
    }
}

// ------------ ERROR & LOGS ------------
export function storeErrorToOffline(method_name, file_name, params = [], error) {
    try {
        let stringParams = "";
        params.forEach(value => {
            stringParams = stringParams + JSON.stringify(value);
        });
        putLogToFirebase(method_name, file_name, stringParams, error);
    } catch (e) {
    }
}

export function putLogToFirebase(method_name, file_name, params, error) {
    let browser = get_browser();
    console.debug("-----------  putLogToFirebase  --------------");
    console.debug("method_name", method_name);
    console.debug("file_name", file_name);
    console.debug("params", params);
    console.debug("error", error);
    console.debug("browser", browser);
    console.debug("-------------------------");

    let obj = {
        cashier: getSessionOperator().username,
        application: "kelnerNovi",
        version: getSessionAppVersion(),
        params: params || null,
        fileName: file_name || null,
        methodName: method_name ? method_name : null,
        error: error ? error : null,
        browser: browser || null
    };
    const colref = doc(DB, "log", "users", AUTH.currentUser.uid, Date.now().toString());
    setDoc(colref, obj).then(_ => {
        console.debug("Uspesno sacuvan LOG na firestoru");
    }).catch(_ => {
        try {
            return offlineDB.open().then(db => {
                const table = db.table("all_errors");
                if (table) {
                    return table.add({
                        method_name: method_name,
                        file_name: file_name,
                        params: params,
                        error: error
                    }, {});
                }
            });
        } catch (e) {

        }
    });
}

// ORDERS

// export async function addOrderOffline(order) {
//     return offlineDB.open().then(async db => {
//         const table = db.table(offline_tables.active_orders);
//         if (table) {
//             let exists = await table.filter(obj => obj.userUid === AUTH.currentUser.uid && obj.id === order.id).first();
//             if (!exists) {
//                 return table.put(order);
//             }
//         }
//     });
// }

// export async function getAllOfflineSubmittedOrders(isPrepared) {
//     return offlineDB.open().then(async db => {
//         const table = db.table(offline_tables.active_orders);
//         if (table) {
//             return table.filter(obj =>
//                 obj.userUid === AUTH.currentUser.uid &&
//                 obj.submitted === true &&
//                 (obj.orderType === ORDER_TYPE.all || obj.orderType === ORDER_TYPE.kitchen) &&
//                 obj.finishedPreparation === isPrepared
//             ).toArray();
//         }
//         return [];
//     });
// }
//
// export async function getAllOfflineSubmittedOrdersForBar() {
//     return offlineDB.open().then(async db => {
//         const table = db.table(offline_tables.active_orders);
//         if (table) {
//             return table.filter(obj =>
//                 obj.userUid === AUTH.currentUser.uid &&
//                 obj.submitted === true &&
//                 // ZAJEBABLI SMO SE ZA OVO JER TREBA DA BUDE PO SVAKOM ITEMU IS PREPARED U SUPROTNOM DESI SE DA KUVAR
//                 // PRIPREMI I PICE NAVODNO
//                 //obj.finishedPreparation === isPrepared &&
//                 (obj.orderType === ORDER_TYPE.all || obj.orderType === ORDER_TYPE.bar)
//             ).toArray();
//         }
//         return [];
//     });
// }

// export async function updateOrderOffline(order) {
//     return offlineDB.open().then(db => {
//         const table = db.table(offline_tables.active_orders);
//         if (table) {
//             let exists = table.filter(obj => obj.userUid === AUTH.currentUser.uid && obj.id === order.id).first();
//             if (exists) {
//                 return table.update(order.id, order);
//             }
//         }
//     });
// }

// export async function deleteOrderOffline(orderId) {
//     return offlineDB.open().then(db => {
//         const table = db.table(offline_tables.active_orders);
//         if (table) {
//             return table.delete(orderId);
//         }
//     });
// }

export async function removeProductByOrderId(orderId, item, flag) {
    //quantity1
    return offlineDB.open().then(async db => {
        const table = db.table(offline_tables.active_orders);
        if (table) {
            let order = await table.filter(tableItem =>
                tableItem.userUid === AUTH.currentUser.uid &&
                tableItem.id === orderId).first();
            if (order) {
                let orderedItems = [...order.items];
                let index = orderedItems.findIndex(orItem => orItem.uid === item.uid);
                if (index !== -1) {
                    if (flag) {
                        orderedItems[index].quantity = orderedItems[index].quantity - item.quantity;
                    }
                    if (orderedItems[index].quantity < 1) {
                        orderedItems.splice(index, 1);
                    }
                }
                if (orderedItems.length === 0) {
                    await table.delete(order.id);
                    await deleteOnlineOrder(order);
                } else {
                    await table.update(order.id, {
                        items: orderedItems
                    });
                }
            }
        }
    });
}

// export async function addNoteForItemOffline(orderId, item) {
//     return offlineDB.open().then(async db => {
//         const table = db.table(offline_tables.active_orders);
//         if (table) {
//             let order = await table.filter(obj =>
//                 obj.userUid === AUTH.currentUser.uid &&
//                 obj.id === orderId).first();
//             let orderedItems = [...order.items];
//             let index = orderedItems.findIndex(orItem => orItem.uid === item.uid);
//             if (index !== -1) {
//                 orderedItems[index] = item;
//                 return table.update(orderId, {
//                     items: orderedItems
//                 });
//             }
//         }
//     });
// }

// export async function cancelOrderOffline(orderId) {
//     return offlineDB.open().then(db => {
//         const table = db.table(offline_tables.active_orders);
//         if (table) {
//             let exists = table.filter(obj => obj.userUid === AUTH.currentUser.uid && obj.id === orderId).first();
//             if (exists) {
//                 return table.update(orderId, {
//                     active: false
//                 });
//             }
//         }
//     });
// }

// export async function removeProductSplitPayment(tableUid, item) {
//     return offlineDB.open().then(async db => {
//         const table = db.table(offline_tables.active_orders);
//         if (table) {
//             let orders = await table.filter(tableItem =>
//                 tableItem.userUid === AUTH.currentUser.uid &&
//                 tableItem.tableUid === tableUid &&
//                 tableItem.submitted === true).toArray();
//             let quantity = item.quantity;
//             let filteredOrders = orders.filter(order => order.items.some(orItem => orItem.uid === item.uid));
//             for (const order of filteredOrders) {
//                 if (quantity > 0) {
//                     let orderedItems = [...order.items];
//                     let index = orderedItems.findIndex(orItem => orItem.uid === item.uid);
//                     if (index !== -1) {
//                         let isBellow = orderedItems[index].quantity <= quantity;
//                         orderedItems[index].quantity = isBellow ? 0 : orderedItems[index].quantity - quantity;
//                         quantity = quantity - orderedItems[index].quantity;
//                         if (orderedItems[index].quantity < 1) {
//                             orderedItems.splice(index, 1);
//                         }
//                         await table.update(order.id, {
//                             items: orderedItems
//                         });
//                         if (orderedItems.length === 0) {
//                             await table.delete(order.id);
//                             await updateActive(order.id, false);
//                         }
//                         if (quantity === 0) {
//                             return;
//                         }
//                     }
//                 }
//             }
//         }
//     });
// }

// -------------- ANALYTICS -------------------
export async function getAllOfflineInvoiceForAnalytics(location) {
    return offlineDB.open().then(async db => {
        let tables = [offline_tables.all_accounting_invoices, offline_tables.all_refunded_invoices,
            offline_tables.all_normal_invoices]
        let all = [];
        for (const table1 of db.tables) {
            if (tables.includes(table1.name)) {
                let invoices = await table1.toArray()
                if (location) {
                    invoices = await table1.where('userLocation').equals(location + AUTH.currentUser.uid).toArray()
                }
                all = [...all, ...invoices]
            }
        }
        return all;
    });
}
