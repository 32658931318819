import { getLastInsertedOfflineInvoice, putInvoiceOffline, storeErrorToOffline } from "../../store/offlineDb";
import { collection, doc, getDocs, limit, orderBy, query, setDoc, startAfter, where } from "firebase/firestore";
import { AUTH, DB } from "../../auth/FirebaseContext";
import { getTimestampFromSdc } from "../invoice";
import moment from "moment";

const INVOICE_TYPE = ["Normal", "Proforma", "Copy", "Training", "Advance"];
export const TRANSACTION_TYPE = ["Sale", "Refund"];
const PAYMENT_TYPE = ["Other", "Cash", "Card", "Check", "WireTransfer", "Voucher", "MobileMoney"];

export function parseInvoice(invoice, formatSdcDate, timestamp) {
  let payments = [];
  if (invoice.paymentMethod !== undefined) {
    invoice.paymentMethod.forEach(value => {
      payments.push({
        paymentType: PAYMENT_TYPE[parseInt(value.paymentType)],
        amount: value.amount
      });
    });
  }
  let transactionType = TRANSACTION_TYPE[parseInt(invoice.transactionType)];
  let invoiceType = INVOICE_TYPE[parseInt(invoice.invoiceType)];
  let sdcDateTime = invoice.sdcDateTime;
  if (formatSdcDate) {
    let date = invoice.sdcDateTime.substring(0, 19);
    sdcDateTime = date.replace("T", " ");
  }
  return {
    ...invoice,
    timestamp: timestamp,
    paymentMethod: payments,
    transactionType: transactionType,
    invoiceType: invoiceType,
    sdcDateTime: sdcDateTime
  };
}

export async function getInvoicesByUser(lastData, arr, resolve, reject) {
  if (lastData === null)
    lastData = await getLastInsertedOfflineInvoice();
  try {
    let q;
    if (lastData) {
      q = await query(collection(DB, `invoices/users/${AUTH.currentUser.uid}`),
        orderBy("sdcDateTime"), startAfter(lastData.sdcDateTime), limit(200));
    } else {
      q = await query(collection(DB, `invoices/users/${AUTH.currentUser.uid}`),
        orderBy("sdcDateTime"), limit(200));
    }
    const querySnapshot = await getDocs(q);
    let snapshotSize = querySnapshot.docs.length;
    if (snapshotSize === 0) {
      resolve(arr);
    }
    let i = 0;
    await querySnapshot.forEach((doc) => {
      let parsedInvoice = parseInvoice(doc.data(), false, doc.id);
      putInvoiceOffline({
        ...parsedInvoice,
        isInsertedOffline: false
      });
      arr.push(parsedInvoice);
      if (i === snapshotSize - 1) {
        getInvoicesByUser(parsedInvoice, arr, resolve, reject);
      }
      i = i + 1;
    });
  } catch (e) {
    console.error("getInvoicesByUser", e);
    storeErrorToOffline("getInvoicesByUserAndLocation", "firestore.js", [], e?.toString());
    reject(e);
  }
}

// Import racuna
export async function getOnlineInvoiceByInvoiceNumber(invoiceNumber) {
  return new Promise(async (resolve, reject) => {
    try {
      let arr = [];
      const q = query(collection(DB, `invoices/users/${AUTH.currentUser.uid}`), where("invoiceNumber", "==", invoiceNumber));
      const querySnapshot = await getDocs(q);
      await querySnapshot.forEach((doc) => {
        arr.push({
          ...doc.data(),
          id: doc.id
        });
      });
      resolve(arr[0]);
    } catch (e) {
      reject(e);
    }
  });
}

const PAYMENT_TYPE_TAX = {
  "Other": 0,
  "Cash": 1,
  "Card": 2,
  "Check": 3,
  "WireTransfer": 4,
  "Wire Transfer": 4,
  "Voucher": 5,
  "MobileMoney": 6
};
const validateTaxInvoice = (taxInvoice) => {
  const keys = ["TaxItems", "Items", "InvoiceType", "Cashier", "InvoiceNumber", "SDCTime_ServerTimeZone", "TotalAmount"];
  for (const key of keys) {
    if (taxInvoice[key] === undefined) {
      throw new Error(`Neispravan podatak ${key}. Ne može biti prazan!`);
    }
  }
};
const taxSdcDateTimeToTimestamp = (sdcDateTime) => {
  try {
    let split = sdcDateTime.split(" ");
    let date = split[0].split(".");
    let time = split[1].split(":");
    return getTimestampFromSdc(new Date(date[2], date[1] - 1, date[0], time[0], time[1], time[2]));
  } catch (e) {
    return 123;
  }
};
export const convertInvoiceType = (data) => {
  switch (data) {
    case "Normal":
      return "0";
    case "Proforma":
      return "1";
    case "Copy":
      return "2";
    case "Training":
      return "3";
    case "Advance":
      return "4";
    default:
      throw new Error(`Neispravan 'InvoiceType'. Može biti ("Normal", "Proforma", "Copy", "Training", "Advance")`);
  }
};

const itemsFromTaxCoreToFirebaseItems = (items) => {
  let arr = [];
  for (let i = 0; i < items.length; i++) {
    arr.push({
      gtin: items[i].GTIN,
      labels: items[i].Labels,
      name: items[i].Name,
      quantity: Number(Number(items[i].Quantity).toFixed(3)),
      totalAmount: Number(Number(items[i].TotalAmount).toFixed(2)),
      unitPrice: Number(Number(items[i].UnitPrice).toFixed(2))
    });
  }
  return arr;
};

const parsePaymentMethodFromTaxInvoice = (data) => {
  let arr = [];
  if (!data.Payments || !Array.isArray(data.Payments)) {
    throw  new Error("'Payments ne postoji ili nije ispravan!");
  }
  for (const payment of data.Payments) {
    const paymentType = PAYMENT_TYPE_TAX[payment.Type];
    if (paymentType === undefined) {
      throw new Error(`Neispravan json file. 'Payments Type' može biti: ("Other", "Cash", "Card", "Check", "WireTransfer", "Voucher", "MobileMoney")`);
    }
    if (payment.Amount === undefined) {
      throw  new Error("'Payments Amount' new može biti prazan!");
    }
    arr.push({
      paymentType: PAYMENT_TYPE_TAX[payment.Type],
      amount: Number(Number(payment.Amount).toFixed(2))
    });
  }
  return arr;
};

const getFormattedSdcDateAndTime = (sdcDateTime) => {
  try {
    let split = sdcDateTime.split(" ");
    let date = split[0].split(".");
    let time = split[1].split(":");
    return moment(new Date(date[2], date[1] - 1, date[0], time[0], time[1], time[2])).format("YYYY-MM-DDTHH:mm:ss.SSSZ");
  } catch (e) {
    return 123;
  }
};

const taxItemsFromTaxToFirebaseTaxItems = (taxItems) => {
  return taxItems.map(tax => ({
    amount: tax.Amount,
    categoryName: tax.CategoryName,
    label: tax.Label,
    rate: tax.Rate
  }));
};

export const convertTransactionType = (data) => {
  switch (data) {
    case "Sale":
      return "0";
    case "Refund":
      return "1";
    default:
      throw new Error(`Neispravan 'TransactionType'. Može biti ("Sale", "Refund")`);
  }
};

export async function insertTaxInvoiceIntoFirestore(taxInvoice) {
  validateTaxInvoice(taxInvoice);
  let timestamp = taxSdcDateTimeToTimestamp(taxInvoice.SDCTime_ServerTimeZone);
  const invoice = {
    dateAndTimeOfIssue: taxInvoice.DateAndTimeOfPos || null,
    buyerCostCenter: taxInvoice.BuyersCostCenter || null,
    buyerTin: taxInvoice.BuyerTin || null,
    cashier: taxInvoice.Cashier,
    invoiceNumber: taxInvoice.InvoiceNumber,
    invoiceType: convertInvoiceType(taxInvoice.InvoiceType),
    items: itemsFromTaxCoreToFirebaseItems(taxInvoice.Items),
    paymentMethod: parsePaymentMethodFromTaxInvoice(taxInvoice),
    referentDocumentDT: taxInvoice.referentDocumentDT || null,
    referentDocumentNumber: taxInvoice.referentDocumentNumber || null,
    sdcDateTime: getFormattedSdcDateAndTime(taxInvoice.SDCTime_ServerTimeZone),
    taxItems: taxItemsFromTaxToFirebaseTaxItems(taxInvoice.TaxItems),
    totalAmount: taxInvoice.TotalAmount,
    transactionType: convertTransactionType(taxInvoice.TransactionType)
  };
  await setDoc(doc(DB, "invoices", "users", AUTH.currentUser.uid, timestamp), invoice, { merge: true });
  const parsed = parseInvoice(invoice, false, timestamp);
  return await putInvoiceOffline(parsed);
}

export async function getInvoicesByUserForPeriod(arr, resolve, reject, period) {
  const startDate = period.startDate;
  const invoice = period.foundInvoice;

  if (!startDate || !AUTH.currentUser) {
    return reject();
  }

  let lastDocument = null;
  let hasMoreData = true;

  const batchSize = 50;

  while (hasMoreData) {
    try {
      let q;

      if (lastDocument) {
        q = query(
            collection(DB, `invoices/users/${AUTH.currentUser.uid}`),
            orderBy("sdcDateTime"),
            startAfter(lastDocument),
            limit(batchSize)
        );
      } else {
        q = query(
            collection(DB, `invoices/users/${AUTH.currentUser.uid}`),
            orderBy("sdcDateTime", "asc"),
            startAfter(moment(startDate).format("YYYY-MM-DD")),
            limit(batchSize)
        );
      }

      const querySnapshot = await getDocs(q);
      const snapshotSize = querySnapshot.docs.length;

      if (snapshotSize === 0) {
        resolve(arr);
        break;
      }

      for (const doc of querySnapshot.docs) {
        const parsedInvoice = parseInvoice(doc.data(), false, doc.id);
        arr.push(parsedInvoice);
        await putInvoiceOffline({
          ...parsedInvoice,
          isInsertedOffline: false
        });
        lastDocument = doc;
      }
    } catch (e) {
      console.error("getInvoicesByUserForPeriod", e);
      storeErrorToOffline("getInvoicesByUserForPeriod", "firestore.js", [], e?.toString());
      reject(e);
      break;
    }
  }
}
